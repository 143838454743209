import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[3]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[5]}")` }}
        ></figure>
        <div className="z-10 relative onePagesHero md:pt-[150px] pt-[100px] pb-[120px] w-4/5 mx-auto">
          <div className="md:w-1/2 w-full onePagesHero__info">
            <h1 className="text-white md:text-start text-center">
              {rpdata?.dbSlogan?.[2].slogan}
            </h1>
            <p className="text-white md:text-start text-center">{rpdata?.dbValues?.[1].description}</p>
            <ButtonContent btnName='contact us' btnphone='208-716-9748'/>
          </div>
          <div className="md:w-1/2 w-full onePagesHero__imgContent">
              <img
                src={rpdata?.dbPrincipal?.logo}
                className='w-full md:w-[70%]'
                alt="Not Found"
                loading="lazy"
              />
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
